export const environment = {
  production: false,
  urlSite: 'http://localhost:4200',
  WebApiUrlCRM: ' https://carantyfunctioncrmdev.azurewebsites.net',
  MSKeyGeneral: 's92yM890f4aCI/wmtdOIKX4yCPXuaaclgfSJJtBpIDTMV2aTwgg3eQ==',
  MSKeyCRM: 'IeITEeTN7UQzHPbjTGoO139sWRnEKZCb5N7F36p4guUiwbvMt0mmaA==',

  webApiUrlDev: 'https://carantyfunctiondev.azurewebsites.net',

  WebApiUrlCarantyamdev: 'https://carantyamdev.azure-api.net/caranty-dev',
  OcpApimKey: 'a4f076c8bf54474599b473d3cede1f3d',

  msalAuth: {
    graphEndpoint: 'https://graph.microsoft.com/v1.0',
    clientId: 'c4c69296-f524-4578-9839-54e837391c41',
    authority: 'https://login.microsoftonline.com/e0ee65fd-9f80-4118-99ca-6784aa65d6e1',
    scopes: [
      'user.read',
      'User.ReadBasic.All',
      'Directory.Read.All',
      'Calendars.Read',
      'Calendars.ReadBasic',
      'Calendars.ReadWrite',
      'Calendars.Read.Shared',
      'CustomSecAttributeDefinition.Read.All',
      'CustomSecAttributeAssignment.Read.All',
      'User.Read.All',
      'Application.Read.All',
    ],
  },

  notificationWss: {
    socketConfig: {
      //url: 'wss://wscarantynotificaservices.azurewebsites.net:443',
      url: 'ws://localhost:3000',
      options: {},
    },
    urlApi: 'http://localhost:3000',
  },
};
